import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import logo from '../images/logo_greative.png';

const HeaderSection = styled.header`
    margin: 30px 0;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    align-items: center;
    column-gap: 44px;

    @media only screen and (max-width: 768px) {
        text-align: center;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
        grid-auto-rows: 1fr;
        column-gap: 0;
    }
`;

const HeaderLogo = styled.div``;
const Headline = styled.span`
    font-family: 'Montserrat';
    font-weight: 600;
    grid-column: span 3;

    @media only screen and (max-width: 768px) {
        line-height: 62px;
    }
`;
const HeaderMenu = styled.nav`
    justify-self: right;
    text-align: center;
    grid-column: span 2;

    @media only screen and (max-width: 768px) {
        justify-self: auto;
    }
`;
const MenuList = styled.ul`
    list-style-type: none;
    padding: 0;
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;

    @media only screen and (max-width: 768px) {
        gap: 40px;
        /* margin-left: -10px; */
    }
`;

const MenuItem = styled.li`
    a {
        color: inherit;
        text-decoration: none;
    }
`;
const Header = () => {
    return (
        <HeaderSection>
            <HeaderLogo>
                <Link to="/">
                    <img src={logo} alt="Greative logo" width="160" />
                </Link>
            </HeaderLogo>
            <Headline>We’re just really good listeners.</Headline>
            <HeaderMenu>
                <MenuList>
                    <MenuItem>
                        <Link to="/contact">CONTACT</Link>
                    </MenuItem>
                </MenuList>
            </HeaderMenu>
        </HeaderSection>
    );
};

export default Header;
